@import (reference) './vars.less';

@font-face {
  font-family: 'Gotham, Gotham-Light';
  src: url('@{brand-font-path}gotham-light.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham, Gotham-Light';
  src: url('@{brand-font-path}gotham-medium.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Gotham, Gotham-Book';
  src: url('@{brand-font-path}gotham-book.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Gotham, Gotham-Book';
  src: url('@{brand-font-path}gotham-medium.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 600;
}

@font-face {
  font-family: 'Gotham, Gotham-Medium';
  src: url('@{brand-font-path}gotham-medium.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
}

@font-light: 'Gotham, Gotham-Light', 'Helvetica', 'Arial', sans-serif;
@font-regular: 'Gotham, Gotham-Book', 'Helvetica', 'Arial', sans-serif;
@font-medium: 'Gotham, Gotham-Medium', 'Helvetica', 'Arial', sans-serif;

@primary-font: @font-regular;
@secondary-font: @font-medium;

@font-weight-light: 400;
@font-weight-bold: 600;
@font-weight-medium: 400;

@designTokensBasePath: '@vaa-component-lib/shared.design-tokens/less/';
@styleImportBasePath: '@virgin-atlantic/component-lib/styles/';

body.page {
  opacity: 1 !important; /* Override the Adobe Target style */

  &.genesys-support-page {
    & * {
      font-family: @primary-font;
    }

    & menu>li>a>span {
      z-index: auto !important;
    }
  }
}

@import './common.less';
@import '@{designTokensBasePath}variables.less';
@import '@{designTokensBasePath}colour/colour.mixin.less';
@import '@{designTokensBasePath}spacing/spacing.mixin.less';
@import '@{designTokensBasePath}font/font.mixin.less';

@import '@{styleImportBasePath}settings/breakpoints.setting.less';
// @import '@{styleImportBasePath}settings/fonts.setting.less';
@import '@{styleImportBasePath}settings/stacking.setting.less';
@import '@{styleImportBasePath}generic/reset.generic.less';
@import '@{styleImportBasePath}elements/root.element.less';
@import '@{styleImportBasePath}elements/html.element.less';
@import '@{styleImportBasePath}elements/body.element.less';
@import '@{styleImportBasePath}objects/wrapper.object.less';

// Importing some specific component styles to prevent page jumping on load
@import '@vaa-component-lib/component.molecule.hero-banner/index.css';
@import '@vaa-component-lib/component.molecule.icon-panel/index.css';
@import '@vaa-component-lib/component.atom.button/index.css';
@import '@vaa-component-lib/component.atom.icons/index.css';
@import '@vaa-component-lib/component.atom.typography/index.css';
@import '@vaa-component-lib/component.layout.container/index.css';